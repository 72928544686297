<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex
            xs12
            sm3
            md3
            lg10
            text-start
            align-center
            pt-2
            class="headingChurch"
            >Parish Types</v-flex
          >
          <v-flex xs12 sm9 md9 lg2 text-end align-center
            ><v-btn
              class="churchbtn"
              color="blue"
              style="color: white"
              @click="dialog = true"
              >ADD PARISH TYPE</v-btn
            ></v-flex
          >
          <v-dialog v-model="dialog" max-width="700px">
            <v-card rounded="lg">
              <v-card-title>
                <span class="editbox">ADD PARISH TYPE</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form>
                  <v-layout wrap justify-center px-2>
                    <v-flex xs12 pr-2>
                      <span class="label"> Parish Type: </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="name"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 pt-5>
                      <span class="label"> Description</span>
                      <v-textarea
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="desc"
                        outlined
                        dense
                        hide-details
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-btn outlined color="grey" text @click="dialog = false"
                  ><span style="color: black">Cancel</span>
                </v-btn>
                <v-btn color="primary" @click="validateInput()"
                  >Save Changes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- Table Section -->

        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-data-table
              :headers="headers"
              :items="parishlist"
              hide-default-footer
              class="elevation-1 text--black"
              id="virtual-scroll-table"
            >
              <!-- <template v-slot:[`item.description`]="{ item }">
                        <div class="truncated" @click="showFullDescription(item.description)">
                            {{ item.description.slice(0, 50) }}....
                        </div>
                    </template> -->
              <template v-slot:[`item._id`]="{ item }">
                <v-icon
                  small
                  color="primary"
                  class="ml-1"
                  @click="editSlider(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  color="red"
                  class="ml-1"
                  @click.stop="openDeleteDialog(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- delete dialog -->
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this Parish Type?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img
                src="@/assets/images/churchb.png"
                width="40px"
                height="40px"
                class="dialog-icon"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn
            style="font-family: interregular; font-size: 13px; color: white"
            color="#cf3a45"
            @click="deletedialog = false"
            >Cancel</v-btn
          >
          <v-btn
            style="font-family: interregular; font-size: 13px; color: white"
            color="blue darken-1"
            @click="confirmDelete"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--edit dialog  -->
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="editbox">EDIT PARISH TYPE</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-layout wrap justify-center px-2>
              <v-flex xs12 pr-2>
                <span class="label"> Parish Type </span>
                <v-text-field
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.name"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pt-5>
                <span class="label"> Description</span>
                <v-textarea
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 justify-end headline grey lighten-2">
          <v-btn outlined color="grey" text @click="closeDialog()"
            ><span style="color: black">Cancel</span>
          </v-btn>
          <v-btn color="primary" @click="validateInputEdit(editingitem._id)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      msg: "",
      deletedialog: false,
      dialog: false,
      name: null,
      desc: "",
      parishlist: [],
      editdialog: false,
      editingitem: [],
      headers: [
        { text: "Name", value: "name", width: "110px" },
        { text: "Description", value: "description", width: "430px" },

        // { text: "Diocese", value: "diocese.name", width: "10px" },
        // // { text: "Parish Type", value: "parishType.name", width: "90px" },
        // { text: "Country", value: "address.country", width: "10px" },
        { text: "Actions", value: "_id", width: "5px" },
      ],
    };
  },
  mounted() {
    this.getData(); // Fetch data when component mounts
  },
  methods: {
    closeDialog() {
      this.editdialog = false;
      this.getData();
    },
    validateInput() {
      if (!this.name) {
        this.msg = "Please provide parish type";
        this.showSnackBar = true;
        return;
      } else if (!this.desc) {
        this.msg = "Please provide short description";
        this.showSnackBar = true;
        return;
      } else {
        this.itemadd();
      }
    },

    validateInputEdit() {
      if (!this.editingitem.name) {
        this.msg = "Please provide parish type";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.description) {
        this.msg = "Please provide short description";
        this.showSnackBar = true;
        return;
      } else {
        this.edit();
      }
    },

    itemadd() {
      var data = {};
      data["name"] = this.name;
      data["desc"] = this.desc;
      axios({
        url: "/add/new/parish/type",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.name = null;
            this.desc = null;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/parish/type/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          //  count: this.count,
          // page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.parishlist = response.data.data;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["id"] = this.editingitem._id;
      user["name"] = this.editingitem.name;
      user["desc"] = this.editingitem.description;

      axios({
        url: "/edit/parish/type",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;

            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/delete/parish/type",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    
    <style scoped>
.dialog-card {
  font-family: interbold;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
  animation: pulse 1s infinite alternate;
}

.dialog-button {
  min-width: 120px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.7;
  }
  to {
    transform: scale(1.1);
    opacity: 1;
  }
}
</style>
    